<template>
  <WhitePageLayout
    :page-icon="pageIcon"
    :title="$t('title')"
    :subtitle="$t('descr')"
  >
    <div class="container">
      <div class="v-grid ">
        <div class="col-2 col-offset">
          <StoryText>
            <template #title>
              <div v-html="$t('texts.0.title')"></div>
            </template>
            <div v-html="$t('texts.0.text')"></div>
          </StoryText>
          <StoryTechnologies :technologiesProps="technologiesArray" />
        </div>
      </div>
    </div>

    <StoryBigImg :big-img="bigImgObject" />

    <div class="container">
      <div class="v-grid ">
        <div class="col-2 col-offset ">
          <StoryText>
            <template #title>
              <div v-html="$t('texts.1.title')"></div>
            </template>
            <div v-html="$t('texts.1.text')"></div>
          </StoryText>
          <VisualizationVideo
            public-id="mediaformat-website/gewst-visualization.mp4"
            >{{ $t("creation") }}
          </VisualizationVideo>
          <StoryText>
            <template #title>
              <div v-html="$t('texts.2.title')"></div>
            </template>
            <div v-html="$t('texts.2.text')"></div>
          </StoryText>
        </div>
      </div>
    </div>
  </WhitePageLayout>
</template>

<script>
import StoryTechnologies from "@/components/story/StoryTechnologies";
import StoryBigImg from "@/components/story/StoryBigImg";
import StoryText from "@/components/story/StoryText";
import VisualizationVideo from "@/components/story/VisualizationVideo";

import { docker, laravel, vue } from "@/utils/technologyImages";

import DESKTOPSTORY_DE from "@/assets/img/story/story-two/story2.png";
import DESKTOPSTORY_EN from "@/assets/img/story/story-two/story2-en.png";
import MOBILESTORY_DE from "@/assets/img/story/story-two/story-2mobile.jpg";
import MOBILESTORY_EN from "@/assets/img/story/story-two/story-2mobile-en.png";
import { metaInfo } from "@/utils/titleHelper";
import WhitePageLayout from "@/components/layouts/WhitePageLayout";
import pageIcon from "@/assets/img/logo/binary.svg";

export default {
  name: "StoryDigitaleProzessoptimierungFuerEnergieversorger",
  components: {
    WhitePageLayout,
    VisualizationVideo,
    StoryTechnologies,
    StoryBigImg,
    StoryText
  },
  data() {
    return {
      pageIcon,
      technologiesArray: [docker, laravel, vue],
      bigImgObject: {
        desktopSrc: this.$t("imgDesktop"),
        mobileSrc: this.$t("imgMobile"),
        mobileAlt: this.$t("imgAlt"),
        desktopAlt: this.$t("imgAlt")
      }
    };
  },
  metaInfo() {
    return metaInfo(this.$t("title"), this.$t("descr"));
  },

  i18n: {
    messages: {
      de: {
        title: "Prozesse effizienter gestalten – Arbeit erleichtern ",
        descr:
          "Digitale Lösungen unterstützen Büroarbeiter bei manuellen, zeitfressenden und fehleranfälligen Tätigkeiten.",
        creation: `Entstehungsverlauf der App.`,
        imgAlt: "Gewerbesteuer Erfassung Alt und Neu",
        imgMobile: MOBILESTORY_DE,
        imgDesktop: DESKTOPSTORY_DE,
        texts: [
          {
            title: "Projekt",
            text: `
            <p class="mb-1">
              <strong
                >Im Zuge der Prozessdigitalisierung passte die 20 Jahre alte
                Microsoft Access Datenbank nicht mehr in die modernen und
                schnelllebigen Bearbeitungsprozesse des börsennotierten
                Unternehmens.</strong
              >
            </p>
            <p class="mb-3">
              Verständnis bildet die Basis für den Erfolg hochkomplexer
              Produkte. In unserer
              <strong>Success-Story 02</strong> wurden zunächst in mehreren
              UX-Workshops die Kundenbedürfnisse ermittelt und in interaktiven
              Klick-Prototypen überprüft. Die Prototyp-Phase löste knifflige
              Nutzeranforderungen und sorgte für ein klares, gemeinsames Bild
              der Produktvision. Im zweiten Schritt wurde die Prototyp-Vorlage
              in eine KI-unterstützte, effiziente und nutzerfreundliche
              Web-Anwendung umgesetzt.
            </p>
          `
          },
          {
            title: "Herausforderung",
            text: `<p class="mb-3">
              Die Bearbeitung von eingescannten Belegen sowie die Verwaltung von
              Stammdaten mit über <strong>100.000 Datensätzen</strong> sollte
              als Webanwendung realisiert werden. In Kooperation mit dem
              <a target="_blank" href="https://www.dfki.de/web/"
                >DFKI Kaiserslautern</a
              >
              sollte eine innovative, KI-basierte Belegerkennung in die
              Prozesskette integriert, sowie die Weitergabe der Daten an ein
              SAP-System umgesetzt werden.
            </p>`
          },
          {
            title: "Ergebnis",
            text: `
            <p class="mb-1">
              Aus einem arbeitsintensiven, papierfressenden und
              nervenaufreibenden Vorgang wurde ein papierloser und optimierter
              Prozess, der vollständig innerhalb einer modernen
              <strong>Web-Applikation</strong> abgebildet und bearbeitet werden
              kann.
            </p>
            <p class="mb-3">
              Falls Sie mehr über dieses Projekte erfahren möchten, dann laden
              wir Sie herzlichst zu uns nach Kaiserslautern ein:
            </p>
          `
          }
        ]
      },
      en: {
        title: "Optimize processes to work more efficiently",
        descr:
          "Use a modern digital application to reduce manual, time-consuming and error-prone steps in office workflows.",
        creation: `Entstehungsverlauf der App.`,
        imgAlt: "Old and new",
        imgMobile: MOBILESTORY_EN,
        imgDesktop: DESKTOPSTORY_EN,
        texts: [
          {
            title: "Project",
            text: `
            <p class="mb-1">
              As digitalization increases, a listed company found that their 20-year-old Microsoft Access database held back their modern and fast-moving work processes.
            </p>
            <p class="mb-3">Understanding user needs is the foundation for the success of any highly complex software product. In our <strong>Success Story 02</strong>, we worked together with our customer to determine their needs through several UX workshops and many interactive prototypes. This prototyping phase allowed us to discover and meet tricky user requirements and ensured a clear, shared vision for the product. In the next step, our prototype template was converted into an AI-based, efficient and user-friendly web application.</p>
          `
          },
          {
            title: "The challenge",
            text: `<p class="mb-3">
              To implement a web application that handles the processing of scanned documents and data administration of a database with over <strong>100,000 records</strong>. Our collaborative project with <a target="_blank" href="https://www.dfki.de/web/"
                >DFKI Kaiserslautern</a
              > produced an innovative AI-based document recognition system which transfers data into a modern SAP system. This solution is now integrated into their optimized workflows.
            </p>`
          },
          {
            title: "The result",
            text: `
            <p class="mb-1">A previously time-consuming, resource-heavy and frustrating process was transformed into an optimized, paperless process that can be fully tracked and edited within a modern <strong>web application</strong>. </p>
            <p class="mb-3">If you would like to learn more about this project, please visit us in Kaiserslautern:</p>
          `
          }
        ]
      }
    }
  }
};
</script>
